.modal-overlay {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.20);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container {
    border-radius: 20px;
    background: var(--color-blue-200);
    overflow: auto;
}

.modal__header {
    display: flex;
    justify-content: flex-end;
}

.modal__close {
    background-color: transparent;
}
