.termsOfUseAndPrivacyPolicy {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-black-200);
}

.termsOfUseAndPrivacyPolicy .header {
    background-color: white;
}

.termsOfUseAndPrivacyPolicy__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 66px;
    padding-left: 16px;
    padding-right: 16px;
    flex: 1;
}


.termsOfUseAndPrivacyPolicy__title {
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
    margin-bottom: 30px;
}

.termsOfUseAndPrivacyPolicy__main__content {
    width: 100%;
    max-width: 816px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 48px;
}

.termsOfUseAndPrivacyPolicy__email__link {
    font-style: normal;
    color: var(--color-black-200);
    display: inline-block;
}

.termsOfUseAndPrivacyPolicy__email__link:hover {
    color: var(--color-gray-600);
}

.termsOfUseAndPrivacyPolicy__section-heading {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 12px;
}

.termsOfUseAndPrivacyPolicy__section-paragraph {
    margin-bottom: 12px;
}

.termsOfUseAndPrivacyPolicy__section-list {
    padding-left: 26px;
}

.termsOfUseAndPrivacyPolicy__section-paragraph__heading {
    font-weight: 600;
}

@media (min-width: 1024px) {
    .termsOfUseAndPrivacyPolicy__main {
        padding-top: 120px;
        max-width: 1440px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .termsOfUseAndPrivacyPolicy__title {
        font-size: 54px;
        line-height: 68px;
        margin-bottom: 50px;
    }

    .termsOfUseAndPrivacyPolicy__main__content {
        font-size: 18px;
        line-height: 26px;
        gap: 32px;
        margin-bottom: 90px;
    }

    .termsOfUseAndPrivacyPolicy__section-heading {
        font-size: 24px;
        line-height: 28px;
    }
}
