.customInput {
    width: 100%;
    height: 40px;
    padding: 12px 21px;
    border-radius: 12px;
    border: 1px solid var(--color-black);
    background: var(--color-gray-300);
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-black);
}

.customInput::placeholder {
    color: var(--color-gray-500);
    font-size: 14px;
    line-height: 24px;
}

.form-error .customInput {
    border-color: var(--color-error);
}

.form-error .form__list__item__input-container__error {
    color: var(--color-error);
    font-size: 12px;
    line-height: 12px;
}

@media (min-width: 1024px) {
    .customInput {
        height: 48px;
        font-size: 16px;
    }
    .customInput::placeholder {
        font-size: 16px;
    }

    .form-error .form__list__item__input-container__error {
        font-size: 14px;
    }
}
