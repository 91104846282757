@import "assets/styles/header.css";
@import "assets/styles/footer.css";
@import "assets/styles/homePage.css";
@import "assets/styles/select.css";
@import "assets/styles/mobileMenu.css";
@import "assets/styles/termsOfUseAndPrivacyPolicy.css";
@import "assets/styles/modal.css";
@import "assets/styles/customInput.css";
@import "assets/styles/phoneInput.css";
@import "assets/styles/toast.css";
@import "assets/styles/spinner.css";
@import "assets/styles/sharedComponents.css";
@import "assets/styles/products.css";
@import "assets/styles/tbml.css";
@import "assets/styles/solutions.css";
@import "assets/styles/aboutUs.css";
@import "assets/styles/contactUs.css";


.main-content-wrapper {
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 55px;
    background-color: var(--color-blue-200);
}

.section-wrapper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.button {
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.countrySelect__menu {
    border: solid 1px #D2E2F4 !important;
    box-shadow: 0px 12px 58.4px 0px #00000029;
}

@media (min-width: 1024px) {
    .main-content-wrapper {
        padding-top: 50px;
    }

    .section-wrapper {
        max-width: 1440px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 900px) {
    .mobile-full {
        width: 100%;
    }

    .main-content-wrapper {
        padding-top: 63px;
    }
}
