.mobileMenu__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.20);
}

.mobileMenu__content {
    display: flex;
    height: 100svh;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px 16px 60px 16px;
    background-color: var(--header);
}

@media (max-width: 900px) {
    .mobileMenu__content {
        padding-top: 8px;
    }
}

.mobileMenu__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 7px;
    padding-bottom: 7px;
}

.mobileMenu__close {
    width: 24px;
    height: 24px;
    background-color: transparent;
}

.mobileMenu__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 40px;
}

.mobileMenu__nav__item__link {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    color: var(--color-black);
}

.mobileMenu__nav__item__link:hover {
    color: var(--color-blue);
}

.mobileMenu__nav__item__link-active {
    color: var(--color-blue);
}