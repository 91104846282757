.message-section {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    max-width: 900px;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-text-dark);
}

.principles-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 80px 115px 75px;
}

.principles-section {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 42px;
    column-gap: 20px;
    padding-left: 40px;
    padding-right: 40px;
}

.esg-section {
    padding-bottom: 80px;
}

.esg {
    padding-left: 0;
    padding-right: 0;
    row-gap: 48px;
    column-gap: 26px;

    .line-1 {
        padding-top: 8px;
    }

    .line-2 {
        padding-top: 6px;
    }

    .median-card {
        .line-0 {
            margin-bottom: 6px;
        }
    }
}

.esg-card-section {
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin-bottom: 80px;
}

.key-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.key-image {
    width: 96px;
    height: 96px;
}

.restricted-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.restricted-list {
    max-width: 870px;
    padding-left: 30px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    gap: 14px;
}

@media (max-width: 1200px) {
    .esg-section {
        padding-right: 40px;
        padding-left: 40px;
    }
}

@media (max-width: 1024px) {
    .principles-section {
        display: flex;
        flex-direction: column;
    }

    .principles-card {
        padding: 80px 40px;
        gap: 24px;
    }

    .esg-card-section {
        padding-left: 0;
        padding-right: 0;
    }

    .esg-section {
        border-radius: 0;
    }
}

@media (max-width: 600px) {
    .principles-card {
        padding: 24px 16px;
    }

    .key-card {
        gap: 4px;
    }

    .message-section > .text-center {
        text-align: left;
    }

    .restricted-content {
        gap: 4px;
    }

    .restricted-list {
        padding-left: 24px;
        gap: 0px;
        font-size: 16px;
        line-height: 22px;
    }

    .key-image {
        width: 48px;
        height: 48px;
        margin-bottom: 8px;
    }

    .line {
        font-size: 14px;
        line-height: 18px;
    }

    .principles-section {
        padding-left: 0;
        padding-right: 0;
        gap: 24px;
    }

    .message-section {
        font-size: 16px;
        line-height: 24px;
        gap: 4px;
    }

    .esg-section {
        padding-bottom: 44px;

        .text-basic {
            line-height: 24px;
        }
    }

    .esg {
        .line-1 {
            padding-top: 0px;
        }
    
        .line-2 {
            padding-top: 0px;
        }
    
        .median-card {
            .line-0 {
                margin-bottom: 8px;
            }
        }
    }

    .esg-card-section {
        margin-bottom: 0;
    }

    .commitment-section {
        padding: 40px 16px;
        gap: 24px;

        .text-small {
            line-height: 24px;
        }
    }
}