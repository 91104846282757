.languageSelect__control {
    border: none !important;
    width: 102px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: inherit;
    display: flex;
    align-items: center;
    background-color: transparent !important;
    box-shadow: none !important;
    cursor: pointer !important;
    position: relative;
}

.languageSelect__indicator {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
}

.languageSelect__arrow-container svg{
    transform: translateY(8px);
}

.languageSelect__arrow-container.menu-open svg{
    transform: rotate(180deg) translateY(-8px);
}

.languageSelect__value-container {
    padding: 0 !important;
    display: flex;
    justify-content: flex-start;
}

.languageSelect__iconValueContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.languageSelect__iconValueContainer__img {
    margin-right: 5px;
}

.languageSelect__iconValueContainer__img,
.languageSelect__iconOption__img {
    width: 12px;
    height: 12px;
}

.languageSelect__menu {
    border: none !important;
    background-color: var(--color-blue-100) !important;
    left: -10px;
    box-shadow: none !important;
    margin-top: 0 !important;
    width: 125px !important;
    border-radius: 0 0 7px 7px !important;
}

.languageSelect__option {
    background-color: transparent !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 10px !important;
}

.languageSelect__iconOption {
    display: flex;
    align-items: center;
    gap: 5px;
}

.countrySelect__control {
    width: 100%;
    padding: 0 21px;
    border-radius: 12px !important;
    border-color: var(--color-black) !important;
    background: var(--color-gray-300) !important;
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer !important;
    box-shadow: 0 0 0 1px transparent !important;
}

.form-error .countrySelect__control {
    border-color: var(--color-error) !important;
}

.countrySelect__placeholder {
    color: var(--color-gray-500) !important;
}

.countrySelect__value-container {
    padding: 0 !important;
}

.countrySelect__indicator {
    color: var(--color-black) !important;
}

.countrySelect__menu {
    margin-top: 1px !important;
    border-radius: 12px !important;
    background-color: var(--color-gray-300) !important;
    overflow: hidden;
    border: none;
    box-shadow: none !important;
}

.countrySelect__option {
    font-size: 14px !important;
    line-height: 24px;
    padding: 6px 21px !important;
}


.countrySelect__option--is-selected {
    background-color: var(--color-blue) !important;
}

.countrySelect__option:active {
    background-color: var(--color-light-gray) !important;
}

.countrySelect__control .countrySelect__input-container {
    color: var(--color-black) !important;
}

@media (min-width: 1024px) {
    .countrySelect__control {
        height: 48px;
        font-size: 16px;
    }

    .countrySelect__menu {
        margin-top: 3px !important;
    }

    .countrySelect__option {
        font-size: 16px !important;
    }
}