.text-image-section-block {
    max-width: 543px;
}

.text-image-section-paragraph {
    font-size: 20px;
    line-height: 30px;
}

.expirience-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 80px;
}

.experience-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.experience-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 65px 24px;
    border-radius: 24px;
}

.experience-content {
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    min-height: 90px;
}

.question-card {
    width: 100%;
    max-width: 900px;
    padding: 32px;
    padding-bottom: 30px;
    background-color: var(--gray-100);
    border-radius: 16px;
    cursor: pointer;
    border: 1px solid transparent;
}

.question-card:hover {
    background-color: #EEF6FF;
}

.question-dropdown {
    overflow: hidden;
}

.question-opened {
    background-color: white;
    border: 1px solid var(--color-blue);
}

.questions-card {
    background-color: white;
    border-radius: 36px;
}

.questions-section {
    padding: 0px 40px 80px 40px;
}

@media(max-width: 1200px) {
    .questions-section {
        padding: 0px 16px;
        margin-bottom: 40px;
    }

    .text-image-section-block {
        text-align: center;
    }

    .experience-card {
        flex-direction: row;
        max-width: none;
    }

    .experience-content {
        display: flex;
        align-items: center;
        text-align: left;
    }
}

@media(max-width: 600px) {
    .questions-section {
        padding: 0px;
        margin-bottom: 0;
    }

    .questions-card {
        border-radius: 0;
        padding: 24px 16px 42px;
    }
    
    .question-card {
        padding: 14px 16px 16px 16px;
    }
    
    .expirience-section {
        margin-bottom: 0;
    }

    .experience-cards {
        gap: 8px;
        margin-bottom: 40px;
    }

    .experience-card {
        padding: 20px 16px;
        border-radius: 12px;

        img {
            width: 48px;
            height: 48px;
        }
    }

    .experience-content {
        font-size: 16px;
        line-height: 20px;
        min-height: 0;
    }

    .text-image-section-paragraph {
        font-size: 16px;
        line-height: 22px;
        text-align: left;
    }

    .text-image-section-block {
        text-align: left;
        gap: 4px;

        .mb-3-5 {
            margin-bottom: 0;
        }
        
        .subtitle {
            font-size: 24px;
            line-height: 34px;
        }
    }

    .mobile-products {
        flex-direction: column-reverse;
        gap: 24px;
    }
}