.spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid #DBE6ED;
    border-right-color: #718CC8;
    display: inline-block;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
