.solutions-image {
    width: 555px;
}

.how-it-works {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 80px 152px;
}

.how-it-works-flow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 72px;
}

.how-it-works-step {
    width: 64px;
    height: 72px;
    z-index: 2;
}

.how-it-works-lines {
    top: 84px;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.how-it-works-line1 {
    margin-top: -82px;
}

.odd-step {
    margin-right: -308px;
}

.even-step {
    margin-left: -308px;
}

.mobile-how-it-works-lines {
    position: absolute;
    top: 68px;
    margin-left: -496px;
}

.mobile-how-it-works-line1 {
    top: 0;
}

.mobile-how-it-works-flow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    > div {
        justify-content: center;
        height: 94px;
    }
}

.how-it-works-section {
    padding-left: 40px;
    padding-right: 40px;
}

.step-line {
    display: flex;
    gap: 48px;
    align-items: center;
}

.reverse-text-image {
    flex-direction: row-reverse;
}

.solutions-text-section {
    padding: 80px 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

@media (max-width: 1400px) {
    .odd-step {
        margin-right: 0;
    }

    .even-step {
        margin-left: 0;
        flex-direction: row;
        text-align: left;
    }

    .how-it-works-step {
        width: 40px;
        height: 40px;
        min-width: 40px;
        z-index: 2;
    }

    .how-it-works {
        padding: 40px 16px;
    }

    .reverse-text-image {
        flex-direction: column;
    }

    .solutions-text-section {
        flex-direction: column;
        padding: 40px 16px;
    }
}

@media (max-width: 650px) {
    .how-it-works-section {
        padding-left: 0px;
        padding-right: 0px;
    }

    .mobile-how-it-works-flow {
        height: 376px;

        > div {
            height: 100%;
        }
    }

    .how-it-works {
        border-radius: 0;
        gap: 24px;
        padding: 24px 16px;
    }

    .step-line {
        gap: 12px;
        margin-right: auto;
        align-items: flex-start;

        .w-112 {
            padding-top: 9px;

            .mb-1 {
                margin-bottom: 9px;
            }
        }
    }

    .mobile-how-it-works-lines {
        margin-left: 0;
        left: 19px;
        top: 6px;
    }

    .solutions-image {
        width: 100%;
        height: 175px;
        object-fit: cover;
        border-radius: 12px;
    }
    
    .solutions-text-section {
        flex-direction: column-reverse;
        gap: 24px;

        > div {
            gap: 4px;
        }

        .text-image-section-paragraph {
            margin-bottom: 0;
        }
    }
}