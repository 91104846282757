.footer {
    width: 100vw;
    display: flex; 
    padding-top: 204px;
    margin-top: -204px;
    align-items: center;
    justify-content: center;
    background-color: var(--color-black);
    color: var(--color-white);
}

.footer__content {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0 80px 0;
}

.mobile-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-mobile-terms {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-decoration: underline;
    margin-bottom: 24px;
}

.mobile-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
}

.mobile-footer-logo {
    display: flex;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    gap: 9px;
    margin-bottom: 24px;
}

.footer__logo {
    width: 84px;
    height: 40px;
    margin-bottom: 28px;
}

.footer__address,
.footer__email {
    display: flex;
    flex-direction: column;
    font-style: normal;
    align-items: center;
    padding-top: 26px;
}

.footer__address {
    margin-bottom: 18px;
    padding-right: 6px;
}

.footer__email {
    margin-bottom: 24px;
    padding-right: 44px;
}

.footer__address__label,
.footer__email__label {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
    color: var(--gray-400);
}

.footer__address__link,
.footer__email__link,
.footer__links__item {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-200);
    text-decoration: none;
}

.footer__address__link {
    text-decoration: none;
}

.footer__email__link {
    text-decoration: underline;
}

.footer__address__link:hover,
.footer__email__link:hover,
.footer__links__item:hover {
    color: var(--color-gray-800);
    transform: translateY(-1px);
}

.footer__address__row:first-child {
    margin-bottom: 4px;
}

.footer__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    margin-bottom: 24px;
}

.footer .languageSelect__menu {
    background-color: transparent !important;
}

.footer .languageSelect__indicator,
.footer .languageSelect__indicator:hover {
    color: var(--color-white);
}

.footer .languageSelect__control:hover,
.footer .languageSelect__control:hover .languageSelect__indicator,
.footer .languageSelect__option:hover {
    color: var(--color-gray-800);
}

@media (min-width: 1400px) {
    .footer__content {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 47px 70px 51px 70px;
    }

    .footer__logo {
        width: 77px;
        height: 22px;
        margin-bottom: unset;
        margin-top: 24px;
    }

    .footer__address,
    .footer__email {
        align-items: flex-start;
    }

    .footer__address__label,
    .footer__email__label {
        line-height: 24px;
        margin-bottom: 16px;
    }

    .footer__address__link,
    .footer__email__link,
    .footer__links__item {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
    }
}

@media (min-width: 1400px) {
    .footer__logo {
        margin-right: 245px;
    }
}


/*termsOfUseAndPrivacyPolicy__footer*/

.termsOfUseAndPrivacyPolicy__footer {
    width: 100%;
}

.termsOfUseAndPrivacyPolicy__footer__content {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 16px 24px;
}

.termsOfUseAndPrivacyPolicy__footer__content__first-row,
.termsOfUseAndPrivacyPolicy__footer__content__second-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.termsOfUseAndPrivacyPolicy__footer__logo {
    display: flex;
    align-items: center;
    gap: 5px;
}

.termsOfUseAndPrivacyPolicy__footer__logo__icon {
    color: var(--color-black);
}

.termsOfUseAndPrivacyPolicy__footer__logo__title {
    width: 76px;
    height: 7px;
}

.termsOfUseAndPrivacyPolicy__footer__email__link,
.termsOfUseAndPrivacyPolicy__footer__links__item {
    font-style: normal;
    font-size: 14px;
    line-height: 23px;
    color: var(--color-black-200);
}

.termsOfUseAndPrivacyPolicy__footer__email:hover {
    transform: translateY(-1px);
}

.termsOfUseAndPrivacyPolicy__footer__email__link:hover,
.termsOfUseAndPrivacyPolicy__footer__links__item:hover {
    color: var(--color-gray-600);
    transform: translateY(-1px);
}

.termsOfUseAndPrivacyPolicy__footer__rights {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    color: var(--color-gray-600);
}

@media (min-width: 1100px) {
    .termsOfUseAndPrivacyPolicy__footer__content {
        padding: 0 100px 16px;
        gap: 36px;
    }

    .termsOfUseAndPrivacyPolicy__footer__content__first-row,
    .termsOfUseAndPrivacyPolicy__footer__content__second-row {
        flex-direction: row;
        justify-content: center;
        gap: 214px;
    }

    .termsOfUseAndPrivacyPolicy__footer__logo {
        gap: 8px;
    }

    .termsOfUseAndPrivacyPolicy__footer__logo__icon {
        width: 84px;
        height: 23px;
    }

    .termsOfUseAndPrivacyPolicy__footer__logo__title {
        width: 122px;
        height: 11px;
    }

    .termsOfUseAndPrivacyPolicy__footer__email__link,
    .termsOfUseAndPrivacyPolicy__footer__links__item {
        font-size: 16px;
    }

    .termsOfUseAndPrivacyPolicy__footer__rights {
        font-size: 14px;
        line-height: 22px;
    }
}

.footer-logo {
    width: 133px;
    display: flex;
    flex-direction: column;
    gap: 39px;
}

.footer-nav {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 54px;
    margin-left: -8px;
}

.footer-top-links {
    display: flex;
    gap: 32px;
}

.footer-language {
    height: 24px;
    display: flex;
    align-items: center;
}

.footer-contacts {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.footer-contact {
    display: flex;
    align-items: center;
    gap: 9px;
}

.footer-icon {
    width: 20px;
    height: 20px;
}

@media(max-width: 1400px) {
    .footer-top-links {
        justify-content: center;
    }

    .footer__email {
        padding-top: 0px;
        padding-right: 0;
        margin-bottom: 0;
        margin-bottom: 24px;
    }

    .footer__address {
        padding-top: 0px;
        padding-right: 0;
        margin-bottom: 24px;
    }

    .footer-nav {
        gap: 0;
    }

    .footer-contact {
        justify-content: center;

        img {
            visibility: hidden;
            width: 0;
            height: 0;
        }
    }

    .footer__email__label,
    .footer__address__label {
        margin-bottom: 10px;
    }
}

@media(max-width: 900px) {
    .footer-language {
        justify-content: center;
    }

    .header__logo {
        justify-content: center;
    }
}

@media(max-width: 600px) {
    .footer-top-links {
        flex-direction: column;
    }

    .footer-contact {
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 0 !important;
    }

    .footer__address__row,
    .footer__email__link,
    .mobile-term-link {
        font-size: 14px;
        line-height: 18px;
    }

    .footer__address {
        margin-bottom: 18px;
    }
}