.contact-section {
    margin-top: 96px;

    .form__list {
        margin-bottom: 24px;
    }
}

.contact-form {
    width: 535px;
}

.contact-form * .form__list__item {
    width: 100%;
}

.contact-map {
    border-radius: 16px;
}

.contact-map-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.contact-form * .form__list__item:last-child * textarea {
    width: 100%;
    min-height: 168px;
    display: flex;
    align-items: flex-start;
    resize: none;
    padding-top: 12px;
    margin-top: -2px;
}

.contact-submit {
    background-color: var(--color-blue);
    border-radius: 100px;
    min-width: 147px;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-right: auto;
}

.contact-submit:hover {
    background-color: #007092;
}

.contact-submit:active {
    background-color: #00546E;
}

.contact-items {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 40px;
}

.contact-address {
    display: flex;
    gap: 48px
}

.decline_form {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    gap: 24px;
    padding: 24px;
    width: 100%;
    background-color: #F4B7B7;
    border-radius: 24px;
}

.decline_form_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    max-width: 422px;
}

.decline_form_link {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #101828;
    text-decoration: none;
    white-space: nowrap;
}

@media (max-width: 1200px) {
    .contact-form {
        width: auto;
    }
    
    .contact-items {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-map {
        aspect-ratio: 320 / 234;
    }
}

@media (max-width: 600px) {

    .decline_form {
        flex-direction: column;
    }

    .contact-section {
        margin-top: 32px;

        .text-tiny {
            font-size: 16px;
            line-height: 22px;  
        }

        .color-dark {
            font-size: 14px;
            line-height: 18px;
        }

        .text-large {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 22px;
        }

        .selected-flag {
            width: 57px;
            display: flex;
            justify-content: center;
            padding-left: 0;
        }

        .react-tel-input {
            .form-control {
                font-size: 14px;
                line-height: 18px;
                padding-left: 64px;
            }
        }

        .form__list__item {
            gap: 6px;
        }

        .form__list__item:last-child {
            margin-top: -8px;

            .customInput {
                margin-top: 0;
                padding: 8px 21px;
                font-size: 14px;
                line-height: 18px;
                height: 118px;
                min-height: 0;
            }
        }

        .form__list {
            gap: 20px;
            margin-bottom: 14px;
        }
    }

    .contact-form {
        padding-left: 16px;
        padding-right: 16px;
    }

    .contact-submit {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
        height: 40px;
        min-height: 0;
    }

    .contact-map {
        border-radius: 0px;
        max-width: 100vw;
        overflow: hidden;
    }

    .contact-address {
        padding-left: 16px;
        padding-right: 16px;
        flex-direction: column;
        align-items: baseline;
        gap: 12px;
    }

    .contact-map-section {
        flex-direction: column-reverse;
    }
}