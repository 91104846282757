.react-tel-input .form-control {
    width: 100%;
    border-radius: 12px;
    height: 40px;
    border-color: var(--color-black);
    background-color: var(--color-gray-300);
    font-size: 16px;
}

.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown.open {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-color: var(--color-black);
    background: var(--color-gray-300);
}

.form-error .react-tel-input .form-control,
.form-error .react-tel-input .flag-dropdown {
    border-color: var(--color-error);
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: var(--color-gray-300);
}

.react-tel-input .country-list {
    border-radius: 12px;
    background-color: var(--color-gray-300);
    margin-top: 1px;
    box-shadow: none;
    font-family: 'Outfit', sans-serif;
}

.react-tel-input .country-list .country:hover {
    background-color: var(--color-light-gray);
}

.react-tel-input .country-list .country.highlight {
    background-color: var(--color-blue);
    color: var(--color-white);
}

.react-tel-input .country-list .country.highlight .dial-code {
    color: var(--color-white);
}

.react-tel-input .country-list .search {
    background-color: var(--color-gray-300);
}

.react-tel-input .country-list .search-box {
    border-radius: 12px;
}

@media (min-width: 1024px) {
    .react-tel-input .form-control {
        height: 48px;
    }

    .react-tel-input .selected-flag {
        width: 60px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-tel-input .form-control {
        padding-left: 70px;
    }

    .react-tel-input .country-list {
        margin-top: 3px;
    }
}

@media (max-width: 374px) {
    .react-tel-input .country-list {
        width: 270px;
    }
}
