* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 20px;
  font-weight: 400;
  color: var(--color-black);
  scroll-behavior: smooth;

  --color-white: #FFF;
  --color-text-dark: #4E5667;
  --color-text-gray: #E4E4E4;
  --color-black-100: #484848;
  --color-black-200: #050505;
  --color-black: #101828;
  --color-blue-100: #F5FAFF;
  --color-blue-200: #F5F8FA;
  --color-light-gray: #D2E2F4;
  --color-blue: #008CB7;
  --color-blue-950: #0D1F41;
  --header: #F5FAFF;
  --gray-100: #F3F6F8;
  --gray-200: #E4E7EC;
  --color-gray-300: #F8FAFF;
  --gray-400: #98A2B3;
  --color-gray-500: #9F9F9F;
  --color-gray-600: #5E5C5C;
  --color-gray-700: #8F8F8F;
  --color-gray-800: #9D9D9D;
  --color-error: #E32626;
}

body {
  overflow-x: hidden;
}


  body.modal-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
  }
