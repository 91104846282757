.home-page__header__nav__list {
    list-style-type: none;
    display: flex;
    gap: 60px;
    padding-left: 52px;
}

.home-page__header__nav__list-item {
    display: flex;
    align-items: center;
}

.home-page__header__nav__list-item__link {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: var(--color-text-dark);
}

.home-page__header__nav__list-item__link:hover {
    color: var(--color-blue);
}

.home-page__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    background-color: var(--color-blue-200);
}

.home-page__section__title {
    width: 100%;
    max-width: 339px;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 5px;
    text-align: center;
}

.home-page__section__description {
    width: 100%;
    max-width: 339px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-bottom: 28px;
}

.home-page__features-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}

.home-page__features-list__item {
    display: flex;
    flex-direction: column;
    width: 343px;
    height: 187px;
    padding: 20px 15px;
    align-items: center;
    border-radius: 14px;
    background: var(--gray-100);
    gap: 16px;
}

.home-page__features-list__item__icon-wrapper {
    display: flex;
    width: 66px;
    height: 66px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-light-gray);
    border-radius: 50%;
}

.home-page__features-list__item__icon-wrapper svg {
    width: 32px;
    height: 32px;
}

.home-page__features-list__item__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
}

/*Solutions*/

#solutions {
    position: relative;
    padding: 60px 0 60px 0;
}

#solutions .home-page__section__title,
#solutions .home-page__section__description {
    padding: 0 16px;
}

.home-page__solutions__small-rings {
    display: block;
    position: absolute;
    right: 0;
    bottom: -25px;
}

.home-page__solutions__large-rings {
    display: none;
}

.home-page__solutions__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    background-color: var(--color-blue);
    padding: 40px 24px 24px 24px;
    color: var(--color-white);
}

.home-page__solutions__list__item {
    width: 311px;
}


.home-page__solutions__list__item__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 16px;
}

.home-page__solutions__list__item__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

/*How it works*/

#how-it-works {
    padding-top: 80px;
    margin-top: -80px;
    padding-bottom: 40px;
}

#how-it-works .home-page__section__description {
    width: 275px;
}

.home-page__howItWorks__steps-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.home-page__howItWorks__steps-list__item {
    width: 343px;
    height: 144px;
    padding: 12px 24px;
    border-radius: 12px;
    background: var(--gray-100);
}

.home-page__howItWorks__steps-list__item__icon-wrapper {
    margin-bottom: 8px;
}

.home-page__howItWorks__steps-list__item__icon-wrapper svg {
    width: 18px;
    height: 18px;
}

.home-page__howItWorks__steps-list__item__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 5px;
}

.home-page__howItWorks__steps-list__item__description {
    color: var(--color-text-dark);
    font-size: 14px;
    line-height: 18px;
}

.home-page__howItWorks__imageAndList {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.home-page__howItWorks__largeImageAndSpiral {
    position: relative;
    display: flex;
    justify-content: center;
}

.home-page__howItWorks__largeImage {
    display: block;
    position: relative;
    width: 343px;
    height: 199px;
    z-index: 1;
}

.home-page__howItWorks__largeImage__frame {
    width: 100%;
    height: 100%;
}

.home-page__howItWorks__largeImage__content {
    position: absolute;
    left: 41px;
    top: 10px;
    width: 268px;
    height: 168px;
}

.home-page__howItWorks__spiral {
    display: none;
}

/*Get started*/

.home-page__getStarted {
    width: 100%;
}

.home-page__getStarted__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 44px 16px;
    background-color: var(--color-blue);
}

.home-page__getStarted__text {
    width: 100%;
    max-width: 966px;
    color: var(--color-white);
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
}

.home-page__getStarted__logo {
    display: flex;
    align-items: center;
    gap: 9px;
    margin-bottom: 24px;
}

.home-page__getStarted__logo__icon {
    width: 84px;
    height: 23px;
    color: var(--color-white);
}

.home-page__getStarted__logo__title {
    width: 143px;
    height: 12px;
}

.home-page__getStartedButton {
    display: inline-flex;
    padding: 18px 32px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: var(--color-blue);
    background-color: var(--color-white);

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.home-page__getStartedButton:hover {
    background-color: #E6E6E6;
}

.home-page__getStartedButton:active {
    background-color: #CCCCCC;
}

.home-page__heroSection {
    position: relative;
    width: 100%;
    height: 350px;
}

.home-page__heroSection__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.home-page__heroSection__video-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.20);
}

.home-page__heroSection__info {
    position: absolute;
    top: 32px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--color-white);
    padding: 0 16px;
}

.home-page__heroSection__title {
    width: 100%;
    max-width: 336px;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 4px;
    text-align: center;
}

.home-page__heroSection__description {
    width: 100%;
    max-width: 294px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-bottom: 32px;
}

.title-with-subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.learn-more {
    background-color: var(--color-blue);
    display: flex;
    justify-content: center;
    align-self: start;
    border-radius: 100px;
    padding: 16px 32px;
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
}

.learn-more:hover {
    background-color: #007092;
}

.learn-more:active {
    background-color: #00546E;
}

.unlocking-cards {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}

.unlocking-card {
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    gap: 8px;
    width: 241px;
    aspect-ratio: 1;
    border-radius: 24px;
    background-color: white;
}

.commitment-section {
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    flex-wrap: wrap;
}

.four-cards {
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
}

@media (min-width: 1024px) {
    .commitment-section {
        padding: 80px 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 36px;
        flex-wrap: wrap;
    }

    .four-cards {
        display: flex;
        gap: 20px;
    }

    .home-page__section {
        padding-top: 80px;
    }

    .home-page__section__title {
        max-width: 600px;
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 16px;
    }

    .home-page__section__description {
        max-width: 670px;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 40px;
    }

    .home-page__features-list {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }

    .home-page__features-list__item {
        width: 325px;
        height: 250px;
        border-radius: 20px;
        gap: 24px;
        padding: 30px 15px;
    }

    .home-page__features-list__item__icon-wrapper {
        width: 96px;
        height: 96px;
    }

    .home-page__features-list__item__icon-wrapper svg {
        width: 48px;
        height: 48px;
    }

    .home-page__features-list__item__title {
        font-size: 22px;
        line-height: 30px;
    }

    /*Solutions*/
    #solutions {
        padding: 80px 40px;
    }

    .home-page__solutions__list {
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: row;
        gap: 115px;
        border-radius: 30px;
        margin-left: unset;
        margin-right: unset;
        padding: 90px 90px 82px 24px;
    }

    .home-page__solutions__list__item {
        width: 460px;
    }

    .home-page__solutions__list__item__title {
        font-size: 32px;
        line-height: 28px;
        margin-bottom: 30px;
    }

    .home-page__solutions__list__item__description {
        font-size: 20px;
        line-height: 30px;
    }

    /*How it works*/
    #how-it-works {
        padding-top: 55px;
        margin-top: -55px;
        padding-bottom: 80px;
    }

    #how-it-works .home-page__section__description {
        width: unset;
    }

    .home-page__howItWorks__steps-list {
        gap: 30px;
    }

    .home-page__howItWorks__steps-list__item {
        width: 559px;
        height: 170px;
        padding: 15px 40px;
        border-radius: 20px;
    }

    .home-page__howItWorks__steps-list__item__icon-wrapper {
        margin-bottom: 12px;
    }

    .home-page__howItWorks__steps-list__item__icon-wrapper svg {
        width: 24px;
        height: 24px;
    }

    .home-page__howItWorks__steps-list__item__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
    }

    .home-page__howItWorks__steps-list__item__description {
        color: var(--color-text-dark);
        font-size: 20px;
        line-height: 30px;
    }


    /*Get started*/
    .home-page__getStarted {
        max-width: 1440px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .home-page__getStarted__content {
        border-radius: 30px;
        padding: 60px 0;
    }


    .home-page__getStarted__text {
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 70px;
    }

    .home-page__getStarted__logo {
        gap: 12px;
        margin-bottom: 30px;
    }

    .home-page__getStarted__logo__icon {
        width: 123px;
        height: 34px;
        color: var(--color-white);
    }

    .home-page__getStarted__logo__title {
        width: 192px;
        height: 17px;
    }

    .home-page__getStartedButton {
        font-size: 16px;
    }

    .home-page__heroSection__title {
        font-size: 58px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
        max-width: 670px;
        margin-bottom: 15px;
    }

    .home-page__heroSection__description {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        max-width: 670px;
        margin-bottom: 32px;
    }

    .home-page__heroSection {
        height: 637px;
    }
}

@media (min-width: 1400px) {
    .home-page__howItWorks__imageAndList {
        flex-direction: row;
        gap: 32px;
        align-items: center;
    }

    .home-page__howItWorks__largeImageAndSpiral {
        order: 1;
    }

    .home-page__howItWorks__steps-list {
        order: 2;
    }

    .home-page__howItWorks__largeImage {
        width: 704px;
        height: 400px;
    }

    .home-page__howItWorks__largeImage__content {
        left: 87px;
        top: 21px;
        width: 544px;
        height: 339px;
    }

    .home-page__howItWorks__spiral {
        display: block;
        position: absolute;
        right: 0;
        bottom: -160px;
    }

    .home-page__solutions__small-rings {
        display: none;
    }

    .home-page__solutions__large-rings {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .home-page__solutions__list {
        position: relative;
        overflow: hidden;
    }
}


@media (max-width: 374px) {
    .home-page__features-list__item {
        width: 297px;
    }

    .home-page__solutions__list__item {
        width: 270px;
    }

    .home-page__howItWorks__steps-list__item {
        width: 270px;
        height: 160px;
    }

    .home-page__howItWorks__largeImage {
        width: 270px;
        height: auto;
        z-index: 1;
    }

    .home-page__howItWorks__largeImage__content {
        left: 33px;
        top: 10px;
        width: 210px;
        height: auto;
    }

    .home-page__heroSection {
        height: 360px;
    }
}


/*Pre-onboarding questionnaire*/

.home-page__form-modal .modal-container {
    padding: 16px;
    overflow-y: auto;
    max-height: 100vh;
}

.home-page__form-modal .modal-content {
    padding-left: 15px;
    padding-right: 15px;
    height: 520px;
    overflow: auto;
}

.preOnboardingQuestionnaire__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form__list {
    display: flex;
    grid-template-columns: auto auto;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-bottom: 20px;
}

.form__list__item:last-child {
    grid-column: span 2;
}

.form__list__item {
    width: 314px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form__list__item__label {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.form__list__item__label__required {
    color: var(--color-error);
    font-size: 14px;
    font-weight: 400;
}

.form__list__item__input-container {
    position: relative;
}

.form__list__item__input-container__error {
    font-size: 14px;
}

.form__submit-button {
    width: 100%;
    padding: 12px 21px;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: var(--color-white);
    background-color: var(--color-black);
    align-self: center;
    margin-bottom: 16px;
}

.form__submit-button:disabled {
    cursor: not-allowed;
    background-color: var(--color-gray-700);
}

.form__submit-button:hover {
    background-color: #404653;
    cursor: pointer;
}

.form__submit-button:active {
    background-color: #666B75;
    cursor: pointer;
}

@media (min-width: 1024px) {
    .home-page__form-modal .modal-container {
        width: 806px;
        /* height: 700px; */
        padding: 30px 40px 30px 40px;
    }

    .home-page__form-modal .modal-content {
        height: unset;
        padding-left: 40px;
        padding-right: 40px;
    }

    .preOnboardingQuestionnaire__title {
        font-size: 32px;
        line-height: 24px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .form__list {
        display: grid;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 18px;
        row-gap: 34px;
        margin-bottom: 30px;
    }

    .form__list__item {
        gap: 14px;
    }

    .form__list__item:last-of-type {
        width: 100%;
    }

    .form__list__item__label {
        font-size: 20px;
        line-height: 24px;
    }

    .form__list__item__input-container__error {
        position: absolute;
        height: 34px;
        padding-top: 3px;
        display: flex;
        align-items: flex-start;
    }

    .form__submit-button {
        height: 54px;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
    }
}

@media (max-width: 374px) {
    .home-page__form-modal .modal-content {
        padding: 0;
    }

    .form__list__item {
        width: 260px;
    }

    .home-page__form-modal .modal-container {
        padding: 16px;
    }
}

@media (max-width: 600px) {
    .learn-more {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .home-page__heroSection__info {
        top: 0px;
    }

    .home-page__heroSection__title {
        margin-bottom: 16px;
    }

    .home-page__getStartedButton {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
    }

    .home-page__getStarted__logo {
        margin-bottom: 24px;
    }

    .home-page__getStarted__content {
        padding: 44px 16px;
    }

    * {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .unlock-block {
        gap: 12px !important;
    }

    .unlocking-card {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .unlocking-cards {
        gap: 8px;
    }

    .solution-image {
        height: 126px;
        object-fit: cover;
    }

    .home-page__getStarted__text {
        font-size: 20px;
        line-height: 26px;
        max-width: 282px;
        margin-bottom: 24px;
    }

    .home-page__heroSection__video {
        object-fit: cover;
    }

    .hero-padding {
        padding-top: 32px;
    }
}

@media (max-width: 1200px) {
    .two-cards {
        flex-direction: column;
    }

    .product-page-image {
        width: 100%;
        max-width: 555px;
    }
}

@media (max-width: 1400px) {
    .limit-section > div:last-child {
        flex-direction: column;
    }

    .unlocking-cards {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .unlocking-card {
        width: 100%;
        flex-direction: row;
        justify-content: start;
        aspect-ratio: unset;
    }
}