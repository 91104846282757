.w-full {
    width: 100%;
}

.w-24 {
    width: 96px;
}

.h-24 {
    height: 96px;
}

.w-50 {
    width: 200px;
}

.w-112 {
    width: 448px;
}

.max-w-51 {
    max-width: 204px;
}

.max-w-116 {
    max-width: none;
}

.max-w-150 {
    max-width: 600px;
}

.max-w-225 {
    max-width: 900px;
}

.grid-col-2 {
    display: grid;
    grid-template-columns: auto auto;
}

.grid-col-3 {
    display: grid;
    grid-template-columns: auto auto auto;
}

.grid-colspan-2 {
    grid-column: span 2;
}

.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.row-reverse {
    flex-direction: row-reverse;
}

@media (min-width: 1200px) {
    .flex-center {
        flex-direction: row;
    }
}

@media (min-width: 1024px) {
    .max-w-116 {
        max-width: 464px;
    }
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.gap-2 {
    gap: 8px;
}

.gap-2-5 {
    gap: 10px;
}

.gap-3 {
    gap: 12px;
}

.gap-4 {
    gap: 16px;
}

.gap-5 {
    gap: 20px;
}

.gap-6 {
    gap: 24px;
}

.gap-8 {
    gap: 32px;
}

.gap-9 {
    gap: 36px;
}

.gap-10 {
    gap: 40px;
}

.gap-12 {
    gap: 48px;
}

.px-5 {
    padding-left: 20px;
    padding-right: 20px;
}

.px-10 {
    padding-left: 40px;
    padding-right: 40px;
}

.py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.mb-20 {
    margin-bottom: 80px;
}

@media (max-width: 1200px) {
    .px-10 {
        padding-left: 16px;
        padding-right: 16px;
    }

    .py-20 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .mb-20 {
        margin-bottom: 40px;
    }
}

.px-12-5 {
    padding-left: 50px;
    padding-right: 50px;
}

.py-12-5 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pl-13-5 {
    padding-left: 54px;
}

.pt-20 {
    padding-top: 80px;
}

.mt-6 {
    margin-top: 24px;
}

.-mt-9 {
    margin-top: -56px;
}

.mb-0-5 {
    margin-bottom: 2px;
}

.mb-1 {
    margin-bottom: 4px;
}

.mb-3 {
    margin-bottom: 12px;
}

.mb-3-5 {
    margin-bottom: 14px;
}

.mb-5 {
    margin-bottom: 20px;
}

.mb-8 {
    margin-bottom: 32px;
}

.mb-12 {
    margin-bottom: 48px;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.text-lightGray {
    color: var(--color-text-dark);
}

.text-black {
    color: var(--color-black);
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: end;
}

.subtitle {
    font-size: 32px;
    line-height: 36px;
    font-weight: 500;
}

.high-title {
    font-size: 48px;
    line-height: 48px;
}

.text-large {
    font-size: 58px;
    line-height: 62px;
    font-weight: 600;
}

.title {
    text-align: center;
    font-size: 38px;
    line-height: 44px;
    font-weight: 500;
}

.nav-button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

.head-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 500;
}

.undertitle {
    color: var(--color-text-dark);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.questions-undertitle {
    color: var(--color-text-dark);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.text-basic {
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
}

.text-fold {
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
}

.text-question {
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
}

.text-small {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: var(--color-text-dark);
}

.text-smaller {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: var(--color-text-dark);
}

.text-small-alt {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: var(--color-text-dark);
}

.text-tiny {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.contact-link {
    color: var(--color-blue);
}

.contact-link:hover {
    color: #007092;
}

.contact-link:active {
    color: #00546E;
}

.color-dark {
    color: var(--color-text-dark);
}

.card {
    background-color: white;
    border-radius: 36px;
}

.rotate-0 {
    rotate: 0deg;
}

.rotate-180 {
    rotate: 180deg;
}

.limit-section {
    width: 100%;
    max-width: 1210px;
    padding-left: 40px;
    padding-right: 40px;
}

.visible-1200 {
    width: 0;
    height: 0;
    visibility: hidden;
}

@media (max-width: 1200px) {
    .visible-1200 {
        width: auto;
        height: auto;
        visibility: visible;
    }

    .hidden-1200 {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    .limit-section {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (max-width: 1000px) {
    .unlock-image {
        width: 100%;
    }

    .card {
        padding: 16px;
        border-radius: 12px;
    }
}

@media (max-width: 600px) {
    .subtitle {
        font-size: 20px;
        line-height: 26px;
    }

    .text-large {
        font-size: 58px;
        line-height: 62px;
        font-weight: 600;
    }
    
    .title {
        font-size: 24px;
        line-height: 34px;
        font-weight: 500;
    }
    
    .undertitle {
        color: var(--color-text-dark);
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }

    .questions-undertitle {
        color: var(--color-text-dark);
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }
    
    .text-basic {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
    }

    .text-fold {
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }

    .high-title {
        font-size: 20px;
        line-height: 20px;
    }

    .text-question {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        gap: 18px;
    }
    
    .text-small {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: var(--color-text-dark);
    }

    .text-smaller {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: var(--color-text-dark);
    }
    
    .text-small-alt {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: var(--color-text-dark);
    }
    
    .text-tiny {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }

    .title-with-subtitle {
        gap: 4px;
    }

    .gap-6 {
        gap: 12px;
    }

    .gap-3 {
        gap: 4px;
    }

    .gap-5 {
        gap: 8px;
    }

    .w-24 {
        width: 48px;
    }

    .h-24 {
        height: 48px;
    }

    .w-50 {
        width: 140px;
    }

    .h-50 {
        height: 140px;
    }

    .mb-3 {
        margin-bottom: 4px;
    }

    .mb-5 {
        margin-bottom: 8px;
    }

    .-mt-9 {
        margin-top: 0;
    }

    .mb-8 {
        margin-bottom: 24px;
    }

    .mb-12 {
        margin-bottom: 24px;
    }

    .pt-20 {
        padding-top: 40px;
    }

    .w-112 {
        width: auto;
    }
}

.line {
    margin-bottom: 8px;
}

.line:last-child {
    margin-bottom: 0;
}

.active-path {
    color: var(--color-blue);
}