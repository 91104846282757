.header {
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-blue-100);
    z-index: 10;
    height: 82px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.header__content {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}

.header__logo {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    gap: 5px;
}

.header__logo__mobile__icon {
    width: 55px;
    height: 15px;
    color: var(--color-black);
}

.header__logo__icon {
    width: 55px;
    height: 15px;
    color: var(--color-black);
}

.header__logo__title {
    width: 76px;
    height: 7px;
}

.header__actions {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--color-black);
}

.header__actions .languageSelect__indicator,
.header__actions .languageSelect__indicator:hover {
    color: var(--color-black);
}

.header__nav,
.header__actions__language {
    display: none;
}

.header__actions__login {
    color: var(--color-black);
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    border-radius: 16px;
    border: 1px solid var(--color-black);
    padding: 8px 22px;
}

.header__actions__login:hover {
    color: var(--color-white);
    background-color: var(--color-black);
}

.header__actions__login:active {
    color: var(--color-white);
    background-color: #404653;
}

.header__actions__menu {
    display: block;
    background-color: transparent;
}

.header .languageSelect__control:hover,
.header .languageSelect__control:hover .languageSelect__indicator,
.header .languageSelect__option:hover {
    color: var(--color-blue);
}

@media (max-width: 1200px) {
    .header__actions {
        padding-right: 16px;
    }
}

@media (max-width: 600px) {
    .header__actions {
        padding-right: 0px;
    }
}

@media (min-width: 1280px) {
    .header {
        height: 82px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .header__content {
        padding-left: 80px;
        padding-right: 62px;
    }

    .header__nav,
    .header__actions__language {
        display: block;
    }

    .header__logo {
        gap: 8px;
    }

    .header__logo__icon {
        color: var(--color-black);
        width: 87px;
        height: 24px;
    }

    .header__logo__title {
        width: 122px;
        height: 11px;
    }

    .header__actions {
        gap: 60px;
    }

    .header__actions__login {
        font-size: 16px;
        font-weight: 500;
        border-radius: 20px;
        padding: 8px 16px;
    }

    .button.header__actions__menu {
        display: none;
    }
}

@media (max-width: 900px) {
    .header {
        height: 63px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}