.fold {
    width: 100%;
    max-width: 940px;
    display: flex;
    justify-content: space-evenly;
    padding: 36px 0px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 30px 80.5px 0px rgba(0,0,0,0.05);
    margin-top: -114px;
    z-index: 2;
    margin-bottom: 80px;
}

.fold-card {
    width: 180px;
    height: 142px;
}

.fold-image {
    margin-bottom: 9px;
}

.stand-card {
    padding: 50px;
    background-color: white;
    border-radius: 16px;
    max-width: 440px;
}

.world-img {
    width: 100%;
    max-width: 1130px;
}

.stands-section {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    gap: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
}

.world-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    padding: 80px 40px;
}

.about-us__heroSection {
    height: 450px;
}

@media(max-width: 1200px) {
    .stands-section {
        display: flex;
        flex-direction: column;

        .key-image {
            margin-bottom: 0;
        }
    }

    .stand-card {
        max-width: none;
        gap: 12px;
    }
}

@media(max-width: 1024px) {
    .fold {
        max-width: none;
        border-radius: 0;
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media(min-width: 1024px) {
    .about-us__heroSection {
        height: 637px;
    }
}

@media(max-width: 600px) {
    .world-section {
        padding: 40px 16px;
        gap: 24px;
    }

    .world-img {
        width: 320px;
    }

    .stands-section {
        padding-left: 16px;
        padding-right: 16px;
        gap: 8px;
    }

    .stand-card {
        padding: 16px;
        gap: 4px;

        .key-image {
            margin-bottom: 8px;
        }
    }

    .fold-image {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
    }

    .fold {
        gap: 24px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: 0;
        margin-bottom: 40px;
    }

    .fold-card {
        width: 80px;
        height: auto;

        .mb-0-5 {
            margin-bottom: 0;
        }
    }
}